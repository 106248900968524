import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Typography,Fab,Toolbar,TextField,Select,FormControl} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import FormGroup from '@material-ui/core/FormGroup';
import Paper from '@material-ui/core/Paper';

import classNames from 'classnames';
import ReactTable from './shares/react_table';
import Utils from '../api/api';
import theme,{styles} from '../layout/theme';

export class HiringList extends Component {
  constructor(props){
    super(props);
    this.state = {
      keyword: '',
      searchClick: false,
      filter: '',
      categories: [],
      statuses: ['Draft','Approved','Expired'],
      status: '',
      category:'',
      sorts: '',
      dateStart: '',
      dateEnd: '',
      selected: [],
    };
  }

  componentDidMount() {
    const $this = this;
    $this.setState({
      sorts: JSON.stringify({pinned:-1,date:-1})
    });
  }

  handleSearch = (e) => {
    var el = document.getElementById('keyword');
    this.setState({ keyword: el.value.trim() });
  }

  filterData = (e) => {
    const $this = this,
          state = $this.state;

    state[e.target.id] = e.target.value.trim();
    $this.setState(state,function(){
      $this.handleFilter();
    });
  }

  handleFilter() {
    let f = this.state.filter;

    if(f==='') {
      f = {};
    } else {
      f = JSON.parse(f);
    }

    if (this.state.status !== '') {
      f.status = parseInt(this.state.status);
    } else {
      if(f.status!==undefined) {
        delete f.status;
      }
    }

    if(this.state.dateStart!==''){
      f.date = {$gte:new Date(this.state.dateStart + ' 23:59:59')};
    } else {
      if(f.date!==undefined && this.state.dateEnd==='') {
        delete f.date;
      }
    }

    if(this.state.dateEnd!==''){
      f.date = {$lte:new Date(this.state.dateEnd + ' 23:59:59')};
    } else {
      if(f.date!==undefined && this.state.dateStart==='') {
        delete f.date;
      }
    }

    this.setState({filter:JSON.stringify(f)});
  }

  handleAdd() {
    this.context.router.history.push('/hiring/add');
  }

  onChangeSelect = (data) => {
    this.setState({selected:data});
  }

  handleStatus(data, status) {
    const $this = this;
    var arr = [];
    data.map(x=>{
      let o = {_id:x._id, status: status};
      arr.push(o);
    });
    Utils._updateMany('hiring', arr, (data) => {
      if (data.status ===' success') {
        $this.setState({
          selected: [],
        }, () => {
          $this.handleFilter();
        });
      }
    });
  }

  render(){
    const $this = this;
    const classes = $this.props.classes;

    const Bulk = [{
        title: 'Lưu trữ (Draft)',
        action: (data) => $this.handleStatus(data, 0)
      },{
        title: 'Publish (Approved)',
        action: (data) => $this.handleStatus(data , 1)
      },{
        title: 'Hết hạn (Expired)',
        action: (data) => $this.handleStatus(data, 2)
      }];
    
    const source = {
      thead: [{title:'Tiêu đề',value:'title'},{title:'Phân loại tin',value:'tags'}, { title: 'Người đăng', value: 'user'}, { title: 'Ngày đăng', value: 'date'}, { title: 'Hồ sơ ứng tuyển', value: 'candidates'}, { title: 'Trạng thái', value: 'status'}],
      tbody: function (n) {
        return ({
          _id: n._id,
          title: n.titleVN,
          date: Utils.getBeautyDate(n.date),
          tags: n.tags.map(x=>{return (<div key={x}>{x}</div>)}),
          user: n.author,
          candidates: n.candidates?n.candidates.length:0,
          status: $this.state.statuses[n.status]
        });
      }
    };
    return(
      <div className="list-page">
        <div className={classNames(classes.auto_toggle, this.state.failed && classes.run)}>
          <Typography variant="body2">{this.state.message}</Typography>
        </div>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">Danh mục tin tuyển dụng</Typography>
        </Toolbar>
        <Paper className={classes.paper}>
          <Toolbar className={classes.tableToolbar}>
          <FormGroup className={classes.formRowToolbar}>
            <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col3}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    id="status"
                    value={$this.state.status}
                    onChange={this.filterData}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.statuses.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col3}>
                  <TextField type="date" id="dateStart" label="Từ" className={classNames(classes.textField + ' form-control-no-bottom')} value={Utils.formatDate(this.state.dateStart)} onChange={this.filterData}
                  margin="normal"/>
                </FormControl>
                <FormControl className={classes.col3}>
                  <TextField type="date" id="dateEnd" label="Đến" className={classNames(classes.textField + ' form-control-no-bottom')} value={Utils.formatDate(this.state.dateEnd)} onChange={this.filterData}
                  margin="normal"/>
                </FormControl>
                <FormControl className={classes.col3}>
                  <TextField type="search" id="keyword" label="Tìm kiếm" className={classNames(classes.textField + ' form-control-no-bottom')} value={this.state.keyword} onChange={(event)=>this.setState({keyword:event.target.value.trim()})}
                  margin="normal"/>
                </FormControl>
              </FormGroup>
            </FormGroup>
            <Fab color="primary" aria-label="search" className={classes.button} onClick={this.handleSearch}>
              <SearchIcon />
            </Fab>
            <Fab color="primary" aria-label="add" className={classes.button} onClick={()=>this.handleAdd()}>
              <AddIcon />
            </Fab>
          </Toolbar>
          <ReactTable apiBulk={Bulk} onChangeSelect={$this.onChangeSelect} showCheckBox={true}  apiDuplicate={true} apiList='hirings' apiSingle='hiring' apiTable='hiring' router={this.context.router} dataMap={source} keyword={this.state.keyword} isSearch={this.state.searchClick} apiSort={this.state.sorts} apiFilter={this.state.filter}>
          </ReactTable>
        </Paper>
      </div>
    )
  }
}

HiringList.propTypes = {
  classes: PropTypes.object.isRequired,
};
HiringList.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(HiringList);

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  FormLabel,
  Toolbar,
  Chip,
  MenuItem,
} from "@material-ui/core";
import { Tabs, Tab } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { FormGroup, FormControl,Switch,FormControlLabel } from "@material-ui/core";
import TabContainer from "./shares/react_tab_container";
import language from "../layout/language";
import Select from "@material-ui/core/Select";
import ImageControl from "./shares/react_image_control";



// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/third_party/font_awesome.min.js";
import "froala-editor/js/third_party/ionicons.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
import "froala-editor/css/third_party/font_awesome.min.css";
import "froala-editor/css/third_party/ionicons.min.css";

import FroalaEditor from "react-froala-wysiwyg";

import classNames from "classnames";
import Utils from "../api/api";
import theme, { styles } from "../layout/theme";


class HiringDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      companies: [],
      locations: [],
      tags: [],
      status: ['Draft','Approved','Expired'],
      hiring: {
        date: new Date().toString(),
        //type: 0,
        urlVN: '',
        urlEN: '',
        thumb: '',
        titleVN: '',
        summaryVN: '',
        detailVN: '',
        descriptionVN: '',
        titleEN: '',
        summaryEN: '',
        detailEN: '',
        descriptionEN: '',
        category: '',
        tags: [],
        salaryMin: 0,
        salaryMax: 1000,
        location: [79],
        company: '',
        isOT: false,
        status: 0,
        pinned: false
      },
      error: {
        vn: false,
        en: false,
        titleVN_error: false,
        summaryVN_error: false,
        detailVN_error: false,
        titleEN_error: false,
        summaryEN_error: false,
        detailEN_error: false
      },
      failed: false,
      message: "",
      isUpdate: false,
      tab: 0,
      open: false,
      options: {'empty':'empty'}
    };
  }
  componentDidMount() {
    //console.log(this.props.action)
    var $this = this;
    var params = this.context.router.route.match.params;

    Utils.getListData("careers", 1, 100, "0,0", null, 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ categories: data.results });
      }
    });

    Utils.getListData("companies", 1, 100, "0,0", null, 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ companies: data.results });
      }
    });

    Utils.getListData("hiring_tags", 1, 1000, '0,0', null, 1, function(data) {
      //console.log(data);
      if (data.status === "success") {
        $this.setState({ tags: data.results });
      }
    });

    Utils.getData('address/cities','',function(data){
      if (data.status === "success") {
        $this.setState({ locations: data.results.cities });
      }
    });

    Utils.getBlockContents(function(options){
      $this.setState({options:options});
    });

    if (params.id !== undefined) {
      $this.setState(
        {
          isUpdate: true
        },
        function() {
          Utils.getSingleData("hiring", params.id, function(res) {
            if (res.status === "success" && res.results !== undefined) {
              var data = {},
                item = res.results;
              for (var property in item) {
                if (item.hasOwnProperty(property)) {
                  data[property] = item[property];
                }
              }
              data._id = item._id;
              if (data.urlVN === undefined) data.urlVN = '';
              if (data.urlEN === undefined) data.urlEN = '';
              if (data.tags === undefined) data.tags = [];
              if (data.status === undefined) data.status = 0;
              if (data.pinned === undefined) data.pinned = false;
              if (data.category === undefined || data.category === null) data.category = "";
              if (!Array.isArray(data.location)) data.location = [data.location];
              $this.setState({ hiring: data });
            }
          });
        }
      );
    }
  }
  onEditorStateChange(field, value) {
    var hiring = this.state.hiring,
      error = this.state.error;
    hiring[field] = value;
    error[field + "_error"] = value == "";

    if (field.indexOf("summary") !== -1) {
      var locale = field.replace("summary", "");
      var temporalDivElement = document.createElement("div");
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = value;
      // Retrieve the text property of the element (cross-browser support)
      var text = temporalDivElement.textContent || temporalDivElement.innerText || "";
      hiring["description" + locale] = text.substring(0, 200);
    }

    this.setState({ hiring: hiring, error: error });
  }
  handleImageChange(value) {
    var hiring = this.state.hiring;
    hiring.thumb = value;
    this.setState({ hiring: hiring });
  }
  handleFieldChange(e,field) {
    var $this = this,
        hiring = this.state.hiring;

    if(e.target.type==='checkbox') {
      hiring[e.target.id] = e.target.checked;
    } else {
      if(field!==undefined) {
        hiring[field] = e.target.value;
      } else {
        hiring[e.target.id] = e.target.value;
        if (e.target.type === "textarea") {
          hiring[e.target.id] = e.target.value.substring(0, 200);
        }
      }

    }
    this.setState({ hiring: hiring },function(){
      console.log($this.state.hiring);
    });
  }
  handleValidate(e, flag) {
    var $this = this,
      hiring = this.state.hiring,
      obj = this.state.error;

    hiring[e.target.id] = e.target.value;

    var p = e.target.id + "_error";

    if (e.target.value.trim() === "" || e.target.value === null) {
      obj[p] = true;
    } else {
      obj[p] = false;
    }
    obj[flag] = false;

    if (e.target.id === "titleVN") {
      var urlVN = Utils.getFriendlyURL(e.target.value.trim());
      hiring.urlVN = urlVN;
    }
    if (e.target.id === "titleEN") {
      var urlEN = Utils.getFriendlyURL(e.target.value.trim());
      hiring.urlEN = urlEN;
    }

    this.setState({ hiring: hiring, error: obj }, () => {
      if (
        !$this.state.error.titleVN_error &&
        !$this.state.error.summaryVN_error &&
        !$this.state.error.detailVN_error
      ) {
        obj["vn"] = false;
      } else {
        obj["vn"] = true;
      }
      if (
        !$this.state.error.titleEN_error &&
        !$this.state.error.summaryEN_error &&
        !$this.state.error.detailEN_error
      ) {
        obj["en"] = false;
      } else {
        obj["en"] = true;
      }
      $this.setState({ error: obj });
    });
  }
  _save() {
    var $this = this;
    var data = this.state.hiring;
    if (this.state.isUpdate) {
      //data._id = this.state.id;
      Utils._update("hiring", data, function(res) {
        //console.log(res);
        if (res.status === "success") {
          $this.context.router.history.push("/hiring/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    } else {
      Utils._add("hiring", data, function(res) {
        if (res.status === "success") {
          $this.context.router.history.push("/hiring/");
        } else {
          $this.setState({
            failed: true,
            message: JSON.stringify(res.message)
          });
        }
      });
    }
  }
  handleSave(e, flag) {
    var params = this.context.router.route.match.params;

    if (params.id !== undefined && !this.state.isUpdate) {
      this.setState({ isUpdate: true });
    }
    var $this = this;
    var obj = this.state.error;
    if (obj[flag]) {
      return;
    } else {
      switch (flag) {
        case "vn":
          obj["titleVN_error"] = this.state.hiring.titleVN === "";
          obj["summaryVN_error"] = this.state.hiring.summaryVN === "";
          obj["detailVN_error"] = this.state.hiring.detailVN === "";
          obj["vn"] =
            obj["titleVN_error"] ||
            obj["summaryVN_error"] ||
            obj["detailVN_error"];
          this.setState({ error: obj }, function() {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        case "en":
          obj["titleEN_error"] = this.state.hiring.titleEN === "";
          obj["summaryEN_error"] = this.state.hiring.summaryEN === "";
          obj["detailEN_error"] = this.state.hiring.detailEN === "";
          obj["en"] =
            obj["titleEN_error"] ||
            obj["summaryEN_error"] ||
            obj["detailEN_error"];
          this.setState({ error: obj }, function() {
            if (!$this.state.error[flag]) $this._save();
          });
          break;
        default:
          return true;
      }
    }
  }
  handleCancel() {
    this.context.router.history.push("/hiring/");
  }
  handleChangeTab = (event, value) => {
    this.setState({ tab: value });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  selectImg(value) {
    var $this = this,
      obj = this.state.hiring;
    obj.thumb = value;
    this.setState({ hiring: obj }, function() {
      $this.handleClose();
    });
  }
  handleChange(e) {
    var obj = this.state.hiring;
    obj.type = e.target.value;
    this.setState({ hiring: obj });
  }
  handleTags(e) {
    var $this = this,
        code = e.keyCode || e.which,
        hiring = $this.state.hiring,
        tags = $this.state.tags,
        appendTags = [];
    // console.log(hiring);
    // console.log(tags);
    if (code === 13) {
      var a_tags = e.target.value.split(","),
          trim_arr = [];
      a_tags.map(tag => {
        let obj = { tag: tag.trim(), appear: 1 };
        if(tag.trim()!==''&&tag!==null){
          trim_arr.push(tag.trim());
          Utils._add("hiring_tag", obj, function(res) {
            //console.log(res);
            if(res.status==='success'){
              appendTags.push(res.data);
            }
          });
        }
      });
      var res = Utils.arrayUnique(hiring.tags.concat(trim_arr));
      hiring.tags = res;
      //console.log(res);
      var final = Utils.arrayUnique(tags.concat(appendTags));
      this.setState({ hiring: hiring, tags: final},function(){
        //console.log($this.state.tags);
      });
    }
  }
  choiceTags(e) {
    var hiring = this.state.hiring;
    hiring.tags.push(e.target.value);
    this.setState({ hiring: hiring }, function() {});
  }
  handleDeleteTag(tag, index) {
    var hiring = this.state.hiring,
      tags = hiring.tags,
      all_tags = this.state.tags;
    tags.splice(index, index + 1);
    hiring.tags = tags;
    this.setState({ hiring: hiring }, function() {
      var _tag = all_tags.filter(x => {
        return x.tag === tag;
      });
      //console.log(_tag);
      if (_tag.length) {
        _tag[0].appear = _tag[0].appear - 1;
        Utils._update("hiring_tag", _tag[0]);
      }
    });
  }
  handleCopy() {
    var data = this.state.hiring;
    data.titleEN = data.titleVN;
    data.subtitleEN = data.subtitleVN;
    data.summaryEN = data.summaryVN;
    data.detailEN = data.detailVN;
    data.descriptionEN = data.descriptionVN;
    this.setState({
      hiring: data
    });
  }
  render() {
    const classes = this.props.classes;
    const value = this.state.tab;
    const hiring = this.state.hiring;
    const editorConfigBasic = Utils.setupBasicEditor();
    const editorConfig = Utils.setupEditor('news',this.state.options);
    var tags = [];
    for (var i = 0, len = this.state.tags.length; i < len; i++) {
      var item = this.state.tags[i];
      var disabled = false;
      for (var y = 0, leny = hiring.tags.length; y < leny; y++) {
        disabled = item.tag === hiring.tags[y].trim();
        if (disabled) break;
      }
      tags.push(
        <option
          disabled={disabled}
          key={i + 1}
          data-id={item._id}
          data-appear={item.appear}
          value={item.tag}
        >
          {item.tag}
        </option>
      );
    }
    return (
      <div className="page-detail">
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.heading} variant="h5">
            Thêm/Sửa Tin tuyển dụng
          </Typography>
        </Toolbar>
        <AppBar position="static" className={classes.subToolbar}>
          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab label="Tiếng Việt" />
            <Tab label="English" />
            {/* <Tab label="한국인"/> */}
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.vn && classes.hide
                )}
              >
                {language.VN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                aria-label="copy"
                onClick={() => this.handleCopy()}
              >
                Copy to [EN]
              </Button>
              <TextField className={classes.textField}
                id="titleVN"
                label={language.VN.title}
                value={hiring.titleVN}
                onChange={event => this.handleValidate(event, "vn")}
                onBlur={event => this.handleValidate(event, "vn")}
                required={true}
                error={this.state.error.titleVN_error}
                margin="normal"
              />
              <TextField className={classes.textField}
                id="urlVN"
                label="Link [SEO]"
                value={hiring.urlVN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col4}>
                  <Typography className={classes.label} type="caption">
                    Ngành nghề
                  </Typography>
                  <Select
                    native
                    id="category"
                    value={hiring.category}
                    onChange={event => this.handleFieldChange(event)}
                  >
                    <option key={0} value="">
                      ...
                    </option>
                    {this.state.categories.map((item, index) => (
                      <option key={index + 1} value={item._id}>
                        {item.nameVN}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.col4}>
                  <Typography className={classes.label} type="caption">
                    Công ty
                  </Typography>
                  <Select
                    native
                    id="company"
                    value={hiring.company}
                    onChange={event => this.handleFieldChange(event)}
                  >
                    <option key={0} value="">
                      ...
                    </option>
                    {this.state.companies.map((item, index) => (
                      <option key={index + 1} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className={classes.col4}>
                  <Typography className={classes.label} type="caption">
                    Địa điểm
                  </Typography>
                  <Select
                    id="location"
                    value={hiring.location}
                    multiple
                    onChange={event => this.handleFieldChange(event,'location')}
                  >
                    {this.state.locations.map((item, index) => (
                      <MenuItem key={index + 1} value={item.Province_Code}>
                        {item.Province}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col6}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="pinned"
                        checked={hiring.pinned.toString()==='true'}
                        onChange={(event)=>this.handleFieldChange(event)}
                      />
                    }
                    label="Ưu tiên"
                  />
                </FormControl>
                <FormControl className={classes.col6}>
                  <FormControlLabel
                    control={
                      <Switch
                        id="isOT"
                        checked={hiring.isOT==='true'||hiring.isOT}
                        onChange={(event)=>this.handleFieldChange(event)}
                      />
                    }
                    label="Có OT"
                  />
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col6}>
                  <Typography className={classes.label}>Trạng thái</Typography>
                  <Select
                    native
                    className={classes.selectMultiLevel}
                    id="status"
                    value={hiring.status}
                    onChange={event => this.handleFieldChange(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {this.state.status.map((item, index) => {
                      return (
                        <option key={index + 1} value={index}>
                          {item}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className={classes.col6}>
                  <TextField className={classes.textField + ' form-control-no-bottom'}
                    id="date"
                    label="Ngày đăng"
                    value={Utils.formatDate(hiring.date)}
                    onChange={event => this.handleFieldChange(event)}
                    margin="normal"
                    type="date"
                  />
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRowInline}>
                <FormControl className={classes.col6}>
                  <TextField className={classes.textField}
                    id="salaryMin"
                    label="Mức lương thấp nhất (USD)"
                    value={hiring.salaryMin}
                    onChange={event => this.handleFieldChange(event)}
                    margin="normal"
                  />
                </FormControl>
                <FormControl className={classes.col6}>
                  <TextField className={classes.textField}
                    id="salaryMax"
                    label="Mức lương cao nhất (USD)"
                    value={hiring.salaryMax}
                    onChange={event => this.handleFieldChange(event)}
                    margin="normal"
                  />
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.formRow}>
                <ImageControl
                  value={hiring.thumb}
                  handleChange={value => this.handleImageChange(value)}
                />
              </FormGroup>
              <FormGroup className={classes.formRow}>
                <FormControl>
                  <Typography className={classes.label} type="caption">
                    Tags (Mỗi tag cách nhau dấu phẩy)
                  </Typography>
                  <TextField className={classes.textField}
                    id="tags"
                    onKeyPress={event => this.handleTags(event)}
                    margin="normal"
                  />
                  <Select
                    native
                    className={"select " + classes.select}
                    onChange={event => this.choiceTags(event)}
                  >
                    <option value="" key={0}>
                      ...
                    </option>
                    {tags}
                  </Select>
                </FormControl>
              </FormGroup>
              <FormGroup className={classes.chipRow}>
                {hiring.tags !== undefined &&
                  hiring.tags !== null &&
                  hiring.tags.map((item, index) => (
                    <Chip
                      label={item}
                      key={index}
                      onDelete={() => this.handleDeleteTag(item, index)}
                      className={classes.chip}
                    />
                  ))}
              </FormGroup>
              <TextField className={classes.textField}
                id="descriptionVN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                value={hiring.descriptionVN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.summaryVN_error}
              >
                {language.VN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={hiring.summaryVN}
                onModelChange={value =>
                  this.onEditorStateChange("summaryVN", value)
                }
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailVN_error}
              >
                {language.VN.detail}
              </FormLabel>
              {
                this.state.options['empty']!=='empty' && (
                  <FroalaEditor
                    tag="textarea"
                    config={editorConfig}
                    model={hiring.detailVN}
                    onModelChange={value =>
                      this.onEditorStateChange("detailVN", value)
                    }
                  />
                )
              }
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={event => this.handleCancel(event)}
                >
                  {language.VN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "vn")}
                >
                  {language.VN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <form className={classes.form} noValidate>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.error.en && classes.hide
                )}
              >
                {language.EN.error.require}
              </Typography>
              <Typography
                type="body2"
                className={classNames(
                  classes.error,
                  !this.state.failed && classes.hide
                )}
              >
                {this.state.message}
              </Typography>
              <TextField className={classes.textField}
                id="titleEN"
                label={language.EN.title}
                value={this.state.hiring.titleEN}
                onChange={event => this.handleValidate(event, "en")}
                onBlur={event => this.handleValidate(event, "en")}
                required={true}
                error={this.state.error.titleEN_error}
                margin="normal"
              />
              <TextField className={classes.textField}
                    id="urlEN"
                    label="Link [SEO]"
                    value={hiring.urlEN}
                    disabled={true}
                    onChange={event => this.handleFieldChange(event)}
                    margin="normal"
                  />
              <TextField className={classes.textField}
                id="descriptionEN"
                rows="5"
                multiline={true}
                label="Meta Description (max. length: 200 characters)"
                value={hiring.descriptionEN}
                onChange={event => this.handleFieldChange(event)}
                margin="normal"
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.summaryEN_error}
              >
                {language.EN.summary}
              </FormLabel>
              <FroalaEditor
                tag="textarea"
                config={editorConfigBasic}
                model={hiring.summaryEN}
                onModelChange={value =>
                  this.onEditorStateChange("summaryEN", value)
                }
              />
              <FormLabel
                className={classes.label_shrink}
                required={true}
                error={this.state.error.detailEN_error}
              >
                {language.EN.detail}
              </FormLabel>
              {
                this.state.options['empty']!=='empty' && (
                  <FroalaEditor
                    tag="textarea"
                    config={editorConfig}
                    model={hiring.detailEN}
                    onModelChange={value =>
                      this.onEditorStateChange("detailEN", value)
                    }
                  />
                )
              }
              <FormGroup row className={classes.formRow}>
                <Button
                  color="default"
                  onClick={event => this.handleCancel(event)}
                >
                  {language.EN.btnCancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={event => this.handleSave(event, "en")}
                >
                  {language.EN.btnSave}
                </Button>
              </FormGroup>
            </form>
          </TabContainer>
        )}
      </div>
    );
  }
}

HiringDetail.propTypes = {
  classes: PropTypes.object.isRequired
};
HiringDetail.contextTypes = {
  router: PropTypes.object
};

export default withStyles(styles)(HiringDetail);
